@import "../../../_styles/variables";

.outputs {
  margin-top: 2rem;

  &__item {
    display: block;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form-wrapper {
    padding: 0 0.5rem;
    width: 50%;
    display: inline-block;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    vertical-align: top;
  }

  &__form {
    padding: 1rem;
    background-color: #fafafa;
  }

  &__input {
    display: block;
    margin-bottom: 0.5rem;
  }

  &__image-wrapper {
    display: inline-block;
    padding: 0 0.5rem;
    width: 50%;
  }

  &__image {
    max-width: 100%;
  }

  &__btn {
    margin-top: 0.5rem;
  }

  .formFailure {
    color: $app-color-red;
  }

  .formSuccess {
    color: $app-color-green;
  }
}
