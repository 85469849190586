@import "../../../_styles/variables";

.ui.styled.accordion .content {
  padding: 0;
}

.dropdownButton {
  width: 10rem;
  margin: 1.6rem 0.5rem 0 0 !important;
  color: white !important;
  background: $app-color-tertiary !important;
}

.formButton {
  margin-left: 1rem !important;
  margin-top: 0.5rem !important;
  color: $app-color-tertiary !important;
  background-color: $app-color-white !important;
  border: 0.05rem solid $app-color-tertiary !important;
}

.addressbookHeader {
  background-color: $app-color-mid-grey !important;
  margin-bottom: 1rem !important;
}

.addressbookAccordion {
  padding: 1rem 0.5rem 0.5rem 0.5rem !important;
  margin-bottom: 1rem !important;
}

.programmeLink {
  text-decoration: underline;
  margin-left: 1rem;
  &:visited {
    color: $app-color-tertiary;
  }
}