//Colors
$app-color-primary: #404951;
$app-color-secondary: #f9a61c;
$app-color-tertiary: #035a84;
$app-color-white: white;
$app-color-light-grey: #f3f3f4;
$app-color-mid-grey: #e8e8e8;
$app-color-grey: #d4d4d5;
$app-color-darkish-grey: #b8b8d152;
$app-color-dark-grey: #404951;
$app-color-darkest-grey: #30373d;
$app-color-black: black;
$app-color-red: #cc3333;
$app-color-green: #00b300;
$app-color-dark-yellow: #ff9500;
$pulsion-blue: #1999d8;
$app-color-active-sidebar: #292f33;
$hover-darken-percentage: 10%;

//Dimensions
$topbar-height: 4.28rem;
$footerbar-height: 2.15rem;
$topbar-margin: 2rem;
$sidebar-opened-width: 18rem;
$sidebar-closed-width: 6rem;
