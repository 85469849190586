.ui.row {
  height: 5rem;
}

.ui.form .field > label {
  color: $app-color-dark-grey;
  font-weight: normal;
}

div.ui.input.formInputs,
.field.formInputs > textarea {
  background-color: $app-color-light-grey;
}

.ui.button.redirectButton {
  @extend %listHeader;
  background: none;
  margin: 0;
  padding: 0;
  color: $app-color-tertiary !important;
}

.ui.button.redirectButtonUnderline {
  text-decoration: underline;
}

.headingDivider {
  padding: 0 0.5rem 0 0.5rem;
  margin: 0;
  display: inline;
}

.TableFunctions .column {
  &.firstColumn {
    padding-left: 0 !important;
  }
  &:last-child {
    padding-right: 0 !important;
  }
}

.textArea {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

fieldset {
  border-width: 0px;
  margin: 0;
  padding: 0;
}

.ControlButtons .button {
  margin-bottom: 1rem;
  float: right;
}

div.ui.input > input,
div.ui.selection.dropdown,
.inputContent {
  background: $app-color-light-grey !important;
  border: none !important;

  .default.text {
    color: $app-color-dark-grey;
  }
}

.embeddedComponent {
  margin: 2rem;
  & > div > div > .loader {
    top: 10rem;
  }
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled,
.ui.form .field.disabled .selection.dropdown {
  opacity: 1 !important;
  color: $app-color-black;
  background-color: transparent !important;
  -webkit-text-fill-color: $app-color-black;
  -webkit-opacity: 1;
  border: none;
}

.ui.form .disabled.field textarea {
  opacity: 0.45 !important;
  border: none;
}

.contactsTable {
  & .required.field > label {
    display: none;
  }
  & .contactDeleteIcon:hover,
  & .contactAddIcon:hover {
    cursor: pointer;
  }
}

.ui.table.contactsTable tr > th {
  color: $app-color-dark-grey;
  font-weight: normal;
}
#lastContactDate::placeholder {
  color: $app-color-dark-grey;
}

.errorText {
  color: $app-color-red;
  font-weight: bold;
}

.smallerFeedbackMargin {
  margin-top: 1rem !important;
}

.spaceAbove {
  margin-top: 2rem !important;
}
