@import "../../../../_styles/variables";

#searchBar {
  padding: 0.7em;
  margin-bottom: 1em;
  color: $app-color-primary;
  background-color: $app-color-white !important;
  @media only screen and (max-width: 64em) {
    width: 60%;
  }
}
#searchButton {
  height: 2.65em;
  display: inline-block;
  @media only screen and (max-width: 64em) {
    width: 40%;
  }
}
