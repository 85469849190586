@import "../../../_styles/variables";

.fileSegment {
  margin: 0rem !important;
  background: $app-color-light-grey !important;
  box-shadow: none !important;
}

.fileDisabled > .fileSegment {
  background: #f9f9fa !important;
}

.fileLoader {
  min-height: 5rem;
}
.pendingFileLoader {
  margin: 0 0.4rem !important;
}

.fileDescInput {
  float: right;
  margin-left: 2rem;
  min-width: 40rem;
}
.fileDescReadOnly {
  float: right;
  margin-left: 2rem;
}

.fileActions {
  float: left;
  margin-top: 0.4rem;
}

.fileUpload {
  padding-left: 0.5rem;
}

.fileUploadLabel {
  display: block;
  color: $app-color-dark-grey;
  margin-bottom: 0.3rem;
}

.uploadSubHeader {
  color: $app-color-dark-grey;
}

.fileText {
  color: $app-color-dark-grey !important;
}
