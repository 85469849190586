@import "../../../../../_styles/variables";

.footerSection {
  background-color: $app-color-darkest-grey;
  color: $app-color-white;
  margin: 0;
  padding: 0.5rem;
  height: $footerbar-height;

  .footer {
    width: 100%;
  }
  .company,
  .versionNumber {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .company {
    float: left;
    .companyLink:visited,
    .companyLink:link {
      color: $pulsion-blue;
    }
    .companyLink:hover {
      color: darken($pulsion-blue, $hover-darken-percentage);
    }
  }

  .versionNumber {
    float: right;
    text-align: right;
  }
}

.ui.grid > .row.footerRowSidebarOpened {
  padding-bottom: 0 !important;
  padding-left: $sidebar-opened-width;
}

.ui.grid > .row.footerRowSidebarClosed {
  padding-bottom: 0 !important;
  padding-left: $sidebar-closed-width;
}
