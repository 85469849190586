@import "./variables";

%listHeader {
  font-size: 1.5rem !important;
  font-style: normal;
  color: $app-color-dark-grey;
  margin-bottom: 1rem;
}

%tooltip {
  padding: 0 1rem;
}
