.files {
  margin-bottom: 1.5rem;

  .files__item {
    line-height: 2rem;
    display: flex;
    align-items: center;
  }

  .files__remove {
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
