@import "../../../_styles/variables";

.ass-sg {
  &-questions {
    padding: 1rem;

    .semantic-calendar {
      display: inline-block;
    }

    .grid {
      .row {
        &:nth-child(2) {
          padding-top: 0;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  &-answer {
    color: $app-color-dark-grey;
    display: block;
    padding-left: 1rem;
  }

  &-group {
    &-label {
      display: block;
      margin-bottom: 2rem;
    }
  }

  &-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 81.125rem) {
  .sgDates {
    min-width: 9.25rem;
  }
}
