@import "../../../../_styles/variables";

%sidebar {
  overflow-y: auto;
  height: calc(
    100vh - (#{$topbar-height} + #{$footerbar-height} + #{$topbar-margin})
  );
}

.sidebarOpen {
  @extend %sidebar;
  margin-left: $sidebar-opened-width !important;
}

.sidebarClosed {
  @extend %sidebar;
  margin-left: $sidebar-closed-width !important;
}

.loggedOut {
  height: 100vh;
}
