@import "../../../_styles/variables";

.ass-baseline {
  &-stages {
    padding: 1.1428571428571428rem;

    .ui.input {
      width: 100%;
    }
  }

  &-label {
    display: block;
    margin-top: 0.5714285714285714rem;
  }

  &-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5714285714285714rem;

    &-checkbox {
      padding: 0 0.7142857142857143rem;
    }
  }

  &-add {
    text-align: right;
  }
  & .baselineDate {
    opacity: 1 !important;
  }
}
