@import "../../../../../_styles/variables";

.appSidebar {
  background-color: $app-color-darkest-grey !important;
  & .logoImages {
    background-color: $app-color-primary !important;
    height: 12rem;
    @media only screen and (max-width: 600px) {
      height: 9rem;
    }
    & .twgSmallLogo {
      padding: 2rem;
    }
    & .twgLogo {
      padding: 3rem;
    }
  }
  & .menuItems {
    & > .item.collapsableMenuItem {
      padding: 0 !important;
      border-left: 0.3rem solid transparent;
      height: 100%;
      width: 100%;
      &.active {
        border-left: 0.3rem solid $app-color-tertiary;
        background-color: $app-color-active-sidebar !important;
      }
      .submenuItem {
        font-size: 0.8rem !important;
        padding-left: 0.5rem;
      }

      & i.icon.menuItemIcon,
      & i.icon.submenuItemIcon {
        color: $app-color-secondary;
      }
      & i.icon.submenuItemIcon {
        float: right;
        text-align: right;
      }
      & a.item,
      & a.item:link,
      & a.item:visited,
      &.mockLink {
        height: 100%;
        width: 100%;
        padding-top: 1em !important;
        padding-bottom: 1em !important;
        padding-right: 1.5em !important;
        padding-left: 1.5em !important;
      }
    }
    & .accordion {
      & .title {
        color: $app-color-white;
        padding: 0rem;
        > .item {
          line-height: 1.3 !important;
        }
      }
      & .content {
        padding: 0 !important;
      }
      & .item.collapsableMenuSubItem {
        background-color: $app-color-dark-grey;
        border-left: 0.3rem solid transparent;
        padding: 0 !important;
        font-size: 0.9rem;
        &.active,
        &:hover {
          border-left: 0.3rem solid $app-color-tertiary;
          background-color: $app-color-active-sidebar !important;
        }
      }
    }
  }
}
