@import "../../../../_styles/variables";

body {
  background-color: $app-color-white;
  & > .grid {
    height: 100%;
  }
}

.content {
  color: $app-color-dark-grey;
}

a:link,
a:visited {
  color: $app-color-mid-grey;
}

.Login {
  & #LoginColumn {
    max-width: 32.14rem;
    & .success {
      color: $app-color-black;
      & a {
        color: $app-color-black;
        text-decoration: underline;
      }
    }
  }
  & #LoginButton {
    color: $app-color-white;
    background-color: $app-color-secondary;
  }
  & .loginTitle {
    color: $app-color-black;
  }
  & .loginPanel,
  & .loginControls {
    background-color: $app-color-white !important;
  }
  & #PasswordResetButton {
    color: $app-color-dark-grey;
    background-color: $app-color-mid-grey;
  }
  &.passwordReset {
    padding: 1em 2em !important;
  }
  & .resetPasswordTooltip {
    padding: 0.5rem 1rem;
  }
}
