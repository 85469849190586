@import "../../../_styles/variables";

.ocr {
  position: relative;
  height: 100vh;

  &__main {
    min-height: 100vh;
    padding: 2rem 1rem 3rem;
    margin-top: 2rem;
  }

  &__dropzone {
    max-width: 25rem;
    height: 10rem;
    border: 1px dashed #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  &__button-group {
    display: flex;
    flex-direction: row;
  }
  &Header {
    font-size: 1.5rem !important;
    font-style: normal !important;
    color: $app-color-primary !important;
    margin-bottom: 1rem !important;
  }
}
