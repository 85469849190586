@import "../../../_styles/variables";
@import "../../../_styles/placeholders";

.reportDateWrapper {
  flex: 1;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  .reportDate {
    text-align: right;
    .reportDateText {
      color: $app-color-tertiary;
    }
  }
}

.runButton {
  float: right;
}

.referralsText {
  color: $app-color-green;
  font-weight: bold;
  margin-right: 0.5rem;
}
