@import "../../../_styles/variables";
$border-radius: 0.3333333333333333rem;

.task-widget {
  min-height: 8.6rem;
  min-width: 20rem;
  border-radius: $border-radius $border-radius 0.6666666666666666rem
    0.6666666666666666rem;
  background: $app-color-dark-grey;
  margin: 1rem;
  box-shadow: 0 0 1.07rem 0 rgba($app-color-black, 0.15);

  // Remove styling
  a {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: block;
  }

  .top {
    height: 2.6rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: white;
    font-size: 1rem;
    &:hover {
      color: $app-color-dark-yellow;
      border-radius: $border-radius $border-radius 0 0;
    }
  }
  .bottom {
    > .stat-box:nth-child(1) {
      background-color: $app-color-light-grey;
      border-radius: 0 0 0 $border-radius;
      > div > div {
        background-color: $app-color-secondary;
      }
    }
    > .stat-box:nth-child(2) {
      background-color: $app-color-white;
      border-radius: 0 0 $border-radius 0;
      > div > div {
        background-color: $app-color-red;
      }
    }

    .stat-box {
      width: 10rem;
      height: 7rem;
      padding: 1rem;
      &:hover {
        background-color: $app-color-darkish-grey;
        color: $app-color-white;
      }
      > p {
        margin: 0;
      }
      > div {
        display: flex;

        > div {
          height: 3.3333333333333335rem;
          width: 0.6666666666666666rem;
          margin-right: 0.8rem;
        }

        > p:first-of-type {
          font-size: 2.5rem;
        }
      }
      > p {
        font-size: 1rem;
      }
    }
    display: flex;
    justify-content: space-around;
  }
}

.tasks-accordion {
  background: $app-color-mid-grey;
  color: $app-color-primary !important;
  &:hover {
    color: $app-color-secondary !important;
  }
}

.full-border-radius {
  border-radius: 0.4rem;
}

.top-border-radius {
  border-radius: 0.4rem 0.4rem 0 0;
}

.margin-bottom {
  margin-bottom: 2rem;
  border-radius: 0.4rem;
}

.negative-margin {
  margin: 0 -1rem;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
